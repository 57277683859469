<template>
  <div>
    <div class="container">
      <!-- header -->
      <BaseHeader
        title="Gestão de tráfego"
        :navigation="navigation"
      ></BaseHeader>
      <div class="container">
        <!-- <date-range-picker
          ref="picker"
          opens="right"
          :locale-data="localeDate"
          :showWeekNumbers="false"
          :showDropdowns="true"
          :autoApply="false"
          :ranges="dateRanges"
          v-model="dateRange1"
        >
          <template
            v-if="dateRange1.startDate && dateRange1.endDate"
            v-slot:input="picker"
            style="min-width: 350px"
          >
            {{ picker.startDate | date }} -
            {{ picker.endDate | date }}
          </template>
          <template
            v-else
            v-slot:input
            class="btn-table"
            title="Selecionar datas"
          >
            Selecione as datas
          </template>
        </date-range-picker> -->
        <div class="cotainer-grid">
          <div class="border">
            <div class="metrica">Metricas - Campanha</div>
            <div class="container-metricas">
              <div class="border metricas-dados">
                <span>Valor investido</span>
                <span class="investimento">R$: 5.000,00</span>
              </div>
              <div class="grid">
                <div class="border metricas-dados">
                  <span>Cliques</span>
                  <span class="investimento">600</span>
                </div>
                <div class="border metricas-dados">
                  <span>Acessos</span>
                  <span class="investimento">635</span>
                </div>
              </div>
              <div class="grid">
                <div class="border metricas-dados">
                  <span>CTR</span>
                  <span class="investimento">2,71%</span>
                </div>
                <div class="border metricas-dados">
                  <span>CPL</span>
                  <span class="investimento">3,99</span>
                </div>
              </div>
              <div class="grid">
                <div class="border metricas-dados">
                  <span>Vendas</span>
                  <span class="investimento">180</span>
                </div>
                <div class="border metricas-dados">
                  <span>Custo por vendas</span>
                  <span class="investimento">3.99</span>
                </div>
              </div>
            </div>
          </div>
          <div class="border">GRÁFICO</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseView from "@/template/BaseView.vue";
//
import moment from "moment-timezone";
import DateRangePicker from "vue2-daterange-picker";
export default {
  components: {
    BaseView,
    DateRangePicker,
  },
  data() {
    return {
      navigation: [
        { link: "Estratégico", to: "/dynamicRoute/estrategico" },
        { link: "Gestão de tráfego", to: this.$route.fullPath },
      ],
      testre: "sdadjas",
      loading: false,
      client: {
        width: 0,
      },
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      dateRange1: {
        startDate: moment(new Date(2015, 0, 1)),
        endDate: moment(),
      },
    };
  },
  computed: {
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        Hoje: [today, today],
        // Ontem: [yesterday, yesterday],
        "Última semana": [lastWeek, today],
        "Este mês": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        "Este ano": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        "Último mês": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "Todo período": [
          new Date(2015, 0, 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
      };
    },
    isMobile() {
      return this.client.width <= 768;
    },
  },
  methods: {
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
  },

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.metricas-dados {
  display: flex;
  justify-content: space-between;
  span {
    font-size: 13px;
  }
  .investimento {
    font-weight: 600;
    font-size: 13px;
  }
}
.container-metricas {
  display: grid;
  gap: 10px;
}
.metrica {
  font-weight: 600;
  padding-bottom: 15px;
}
.border {
  border: 1px solid #ededed;
  border-radius: 4px;
  padding: 15px;
}
.cotainer-grid {
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 20px;
}
</style>
